import { createTheme } from '@mui/material';
import { jbColors } from './jbColors';
import MuiButton from './components/MuiButton';
import { palette } from './palette';
import { rem, typography } from './typography';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    // PLACEHOLDER FOR BUTTON VARIANT OVERRIDES, LIKE BJB ANIMATED DASH
    dashed: true;
  }
}

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    hint: true;
  }
}

export const theme = createTheme({
  palette,
  typography,
  components: {
    MuiTypography: {
      variants: [
        {
          props: { variant: 'hint' },
          style: {
            fontSize: rem(10),
            fontFamily: 'VerlagSSmBook',
          },
        },
      ],
    },
    MuiButton: MuiButton,
    MuiAppBar: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiMenu: {
      defaultProps: {
        autoFocus: false,
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: palette.action.hover,
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: 'VerlagSSmBook',
          textTransform: 'none',
          fontSize: rem(14),
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        color: 'primary',
      },
      styleOverrides: {
        colorPrimary: {
          color: palette.primary.light,
        },
        colorSecondary: {
          color: palette.secondary.light,
        },
      },
    },
    MuiTable: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: jbColors.black[60],
          fontFamily: 'VerlagSSmBook',
          borderBottom: `1px solid ${jbColors.black[20]}`,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          verticalAlign: 'middle',
        },
        head: {
          '&:hover': {
            backgroundColor: 'transparent',
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-head': {
            color: palette.secondary.main,
            borderBottom: `2px solid ${palette.primary.light}`,
            padding: '20px',
            fontWeight: 400,
            lineHeight: '1.5rem',
          },
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          color: jbColors.black[50],
          fontFamily: 'VerlagSSmBook',
        },
        displayedRows: {
          color: jbColors.black[50],
          fontFamily: 'VerlagSSmBook',
        },
        input: {
          color: jbColors.black[50],
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          fontFamily: 'VerlagSSmBook',
          fontSize: rem(14),
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        variant: 'filled',
      },
      styleOverrides: {
        root: {
          fontFamily: 'VerlagSSmBook',
          fontSize: rem(12),
          alignItems: 'center',
        },
        action: {
          display: 'flex',
          alignItems: 'center',
          padding: 0,
        },
      },
    },
    MuiChip: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          fontSize: rem(12),
          borderRadius: 0,
          textTransform: 'uppercase',
          borderColor: palette.primary.light,
          height: '26px',
        },
      },
    },
  },
});
