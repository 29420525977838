import React from 'react';
import { Box } from '@mui/material';
import { CaoTable } from 'components/CaoTable';
import useCaoTable from './hooks/useCaoTable';

export const LandingPage = () => {
  const {
    eventDashboardTableOne,
    eventDashboardTableTwo,
    eventDashboardTableThree,
    isLoading,
    columns,
  } = useCaoTable();

  return (
    <Box padding={6}>
      {
        <Box
          data-testid="landingpage-event-dashboard"
          sx={{ display: 'flex', columnGap: 4 }}
        >
          <CaoTable
            columns={columns}
            data={eventDashboardTableOne}
            data-testid="landingpage-event-dashboard-cao-table"
            isLoading={isLoading}
            lastColumnAlignment={'center'}
          />
          <CaoTable
            columns={columns}
            data={eventDashboardTableTwo}
            data-testid="landingpage-event-dashboard-cao-table"
            isLoading={isLoading}
            lastColumnAlignment={'center'}
          />
          <CaoTable
            columns={columns}
            data={eventDashboardTableThree}
            data-testid="landingpage-event-dashboard-cao-table"
            isLoading={isLoading}
            lastColumnAlignment={'center'}
          />
        </Box>
      }
    </Box>
  );
};
