import { Box } from '@mui/material';

import { BASENAME } from './../config';
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { LandingPage } from 'apps/LandingPage/LandingPage';
const Components = React.lazy(() => import('../apps/components'));

/**
 * AppRoutes will load the app routes.
 * @returns
 */
const AppRoutes = () => {
  return (
    <BrowserRouter basename={BASENAME}>
      <Box>
        <Routes>
          <Route
            element={<Components />}
            path="/components"
          />
          <Route
            element={<LandingPage />}
            path="/"
          />

          <Route
            element={
              <Navigate
                replace
                to="/"
              />
            }
            path="*"
          />
        </Routes>
      </Box>
    </BrowserRouter>
  );
};

export default AppRoutes;
