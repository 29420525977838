import React, { useMemo } from 'react';
import {
  DashBoardMenu,
  EventStatus,
} from './../../../store/types/EventDashboard';
import { useGetEventDashboardDataQuery } from 'store/eventDashboardApi';
import { EventDashboard } from 'store/types/EventDashboard';
import { ColumnDef } from '@tanstack/react-table';

const useCaoTable = () => {
  const { data: eventDashboardData, isLoading } = useGetEventDashboardDataQuery(
    undefined,
    { refetchOnMountOrArgChange: true }
  );
  const responseMapper = (
    resData: EventDashboard | undefined,
    selectedKeys: Array<string>
  ) => {
    let tableResponse: Array<EventStatus> = [];
    if (resData) {
      tableResponse = selectedKeys
        .map((key: string) =>
          Array.isArray(resData.dashboardMenu[key as keyof DashBoardMenu])
            ? resData.dashboardMenu[key as keyof DashBoardMenu]
            : []
        )
        .flat();
    }
    return tableResponse;
  };

  const eventDashboardTableOne = React.useMemo(
    () => responseMapper(eventDashboardData, ['dnNew', 'dnUpd', 'dnUpdef']),
    [eventDashboardData]
  );
  const eventDashboardTableTwo = useMemo(
    () => responseMapper(eventDashboardData, ['dnDe', 'dnDr', 'dnDf']),
    [eventDashboardData]
  );
  const eventDashboardTableThree = useMemo(
    () => responseMapper(eventDashboardData, ['dnCan']),
    [eventDashboardData]
  );

  const columns: ColumnDef<EventStatus>[] = [
    {
      accessorKey: 'displayDesc',
      header: 'Notification Dashboard',
    },
    {
      accessorKey: 'count',
      header: 'Count',
    },
  ];

  return {
    eventDashboardTableOne,
    eventDashboardTableTwo,
    eventDashboardTableThree,
    isLoading,
    columns,
  };
};

export default useCaoTable;
