import {
  AppBar,
  Box,
  Divider,
  Grid,
  Toolbar,
  Typography,
  BoxProps,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useGetUserDataQuery } from 'store/usersApi';

/**
 * Header Component to show the header of the application based on the Main Layout
 */
const Header = (props: BoxProps) => {
  const { t } = useTranslation(['common']);
  const { data: user } = useGetUserDataQuery(undefined);

  return (
    <AppBar
      color="primary"
      elevation={0}
      position="sticky"
    >
      <Toolbar>
        <Grid
          container
          justifyContent="space-between"
        >
          <Grid
            alignItems="center"
            container
            item
            xs={4}
          >
            <Box
              alignItems="center"
              display="flex"
            >
              <Box
                alignItems="center"
                display="flex"
              >
                <img
                  alt={t('common:app')}
                  data-testid="header-logo"
                  src={`/images/logo.png`}
                  width="150px"
                />
              </Box>
              <Typography
                color="primary.contrastText"
                variant="h5"
              >
                CAO
              </Typography>
            </Box>
          </Grid>
          <Grid
            alignItems="center"
            container
            item
            justifyContent="end"
            xs={4}
          >
            <Box
              alignItems="center"
              display="flex"
            >
              <Box
                alignItems="center"
                display="flex"
              >
                <Box
                  sx={{
                    mr: 1,
                    display: { xs: 'none', sm: 'none', md: 'block' },
                  }}
                >
                  <Typography
                    align="right"
                    color="primary.contrastText"
                    component="p"
                    data-testid="header-username"
                    variant="body2"
                  >
                    {user?.name || '-'}
                  </Typography>
                  <Typography
                    component="p"
                    data-testid="header-lastlogin"
                    variant="hint"
                  >
                    Last login:{' '}
                    {user?.lastLogin
                      ? dayjs(user?.lastLogin).format('HH:mm A, DD/MM/YYYY')
                      : ''}
                  </Typography>
                </Box>
              </Box>
              <Divider
                flexItem
                orientation="vertical"
                sx={{
                  mx: {
                    xs: 1,
                    sm: 2,
                  },
                }}
                variant="middle"
              />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default React.memo(Header);
