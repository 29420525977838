import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../config';

export type User = {
  name: string;
  lastLogin: string;
};

export const userApi = createApi({
  reducerPath: 'userApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  endpoints: (builder) => ({
    getUserData: builder.query<User, undefined>({
      query: () => ({ url: `/username`, method: 'GET' }),
    }),
  }),
});

export const { useGetUserDataQuery } = userApi;
