import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_URL } from '../config';
import { EventDashboard } from './types/EventDashboard';

export const eventDashboardApi = createApi({
  reducerPath: 'eventDashboardApi',
  baseQuery: fetchBaseQuery({ baseUrl: API_URL }),
  tagTypes: ['EventDashboard'],
  endpoints: (builder) => ({
    getEventDashboardData: builder.query<EventDashboard, undefined>({
      query: () => ({ url: `/dashboard`, method: 'GET' }),
      providesTags: ['EventDashboard'],
    }),
  }),
});

export const { useGetEventDashboardDataQuery } = eventDashboardApi;
