import React, { useMemo } from 'react';
import {
  Skeleton,
  Paper,
  Table as MuiTable,
  TableCell,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  ColumnDef,
  useReactTable,
  flexRender,
  getCoreRowModel,
} from '@tanstack/react-table';

export interface TableProps<TData> {
  data: TData[];
  columns: ColumnDef<TData>[];
  isLoading?: boolean;
  skeletonCount?: number;
  skeletonHeight?: number;
  lastColumnAlignment?:
    | 'left'
    | 'center'
    | 'right'
    | 'justify'
    | 'inherit'
    | undefined;
}
const CaoTable = <TData extends object>({
  data,
  columns,
  isLoading,
  skeletonCount = 10,
  skeletonHeight = 28,
  lastColumnAlignment,
  ...rest
}: TableProps<TData>) => {
  const memoizedData = useMemo(() => data, [data]);
  const memoizedColumns = useMemo(() => columns, [columns]);
  const { getHeaderGroups, getRowModel, getAllColumns } = useReactTable({
    data: memoizedData,
    columns: memoizedColumns,
    getCoreRowModel: getCoreRowModel(),
  });
  const skeletons = Array.from({ length: skeletonCount }, (x, i) => i);
  const columnCount = getAllColumns().length;
  return (
    <Paper
      elevation={2}
      style={{ padding: '1rem 0px' }}
      {...rest}
    >
      <MuiTable>
        <TableHead>
          {getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableCell key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody>
          {isLoading ? (
            <>
              {skeletons.map((skeleton) => (
                <TableRow
                  data-testid="progressbar"
                  key={skeleton}
                >
                  {Array.from({ length: columnCount }, (x, i) => i).map(
                    (elm) => (
                      <TableCell key={elm}>
                        <Skeleton height={skeletonHeight} />
                      </TableCell>
                    )
                  )}
                </TableRow>
              ))}
            </>
          ) : (
            getRowModel().rows.map((row) => (
              <TableRow key={row.id}>
                {row.getVisibleCells().map((cell, index) => (
                  <TableCell
                    align={
                      row.getVisibleCells().length - 1 === index
                        ? lastColumnAlignment
                        : 'left'
                    }
                    key={cell.id}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          )}
        </TableBody>
      </MuiTable>
    </Paper>
  );
};

export default CaoTable;
